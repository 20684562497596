import * as React from "react"
import Layout from '../components/Layout';
import Header from '../components/Header';
import Footer from '../components/Footer';
import SEO from "../components/seo"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import editorImg from '../images/editor.png';
import examplesImg from '../images/examples.png';
import desktopBG from '../images/desktop-bg.png';
import howItWorksUpload from '../images/how-it-works-upload.png';
import howItWorksBuild from '../images/how-it-works-build.png';
import howItWorksPublish from '../images/how-it-works-publish.png';
import qualityDemo from '../images/quality-demo.mp4';

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Pictarize | Building and Publishing web AR experiences without coding"/>

      <div className="home">
	<Header/>
	<div className="hero-section">
	  <div className="container">
	    <div className="row">
	      <div className="col text col-12 col-md-6">
		<div className="title">Pictarize Studio</div>
		<div className="sub-title">web based drag-n-drop editor, building and publishing interative web AR apps with 3D models, videos, audios and texts!</div>

		<div className="highlight">Build for web browsers. No app download required.</div>
		<div className="highlight">Free to use.</div>
	      </div>
	      <div className="col col-12 col-md-6">
		<div className="editor">
		  <div className="device-wrapper">
		    <div className="bg">
		      <img alt="bg" className="bg-img" src={desktopBG} />
		    </div>
		    <div className="fg">
		      <img alt="pictarize-editor" className="fg-img" src={editorImg}/>
		    </div>
		  </div>
		</div>
	      </div>
	    </div>
	  </div>
	</div>

	<div className="section demo-section light-bg-section">
	  <div className="container">
	    <h1>88 seconds</h1>
	    <h2>From launching the studio to a published app &nbsp;
	      <FontAwesomeIcon icon='arrow-down'/>
	    </h2>
	    <div className="iframe-container">
	      <iframe width="560" height="315" src="https://www.youtube.com/embed/J6_oi-pFKpg" title="Pictarize Studio Demo" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
	    </div>

	    <div className="launch-container">
	      <h2>Try it now. No registration is required. We believe in our project!</h2>
	      <a rel="noreferrer" href="https://studio.pictarize.com" target="_blank" className="ga-event" data-ga-event="launch_studio">Launch Studio</a>
	    </div>
	  </div>
	</div>

	<div className="section cases-section dark-bg-section">
	  <div className="container">
	    <h1><span className="small">We call it...</span> Augmented Pictures</h1>
	    <div>Posters, Books, Cards, Packagings, and more...</div>
	    <h2>Check out this <a href="https://blog.pictarize.com/examples-augmented-images/" target="_blank" rel="noreferrer" >article</a> for 10 inspiring ideas to get your mind rolling!</h2>

	    <div className="row">
	      <div className="col col-12 col-lg-2"></div>
	      <div className="col col-12 col-lg-8">
		<a href="https://blog.pictarize.com/examples-augmented-images/" target="_blank" rel="noreferrer">
		  <img alt="web augmented reality examples" className="examples-banner" src={examplesImg} />
		</a>
	      </div>
	      <div className="col col-12 col-lg-2"></div>
	    </div>
	  </div>
	</div>

	<div className="section how-it-works-section light-bg-section">
	  <div className="container">
	    <h1>How it works</h1>
	    <div className="row">
	      <div className="col col-12 col-md-4">
		<div className="wrapper">
		  <img alt="pictarize-step1" src={howItWorksUpload} />
		  <div className="title">1. Upload</div>
		  <p className="description">Upload any images of your choice, and make them the targets of the AR effects!</p>
		</div>
	      </div>
	      <div className="col col-12 col-md-4">
		<div className="wrapper">
		  <img alt="pictarize-step2" src={howItWorksBuild} />
		  <div className="title">2. Build</div>
		  <p className="description">Drag-n-drop AR contents in the online editor. What you see is what you get!</p>
		</div>
	      </div>
	      <div className="col col-12 col-md-4">
		<div className="wrapper">
		  <img alt="pictarize-step3" src={howItWorksPublish} />
		  <div className="title">3. Publish</div>
		  <p className="description">Publish your app directly in the editor. A unique web URL will be generarted!</p>
		</div>
	      </div>
	    </div>
	    <h2>Check out this <a href="https://blog.pictarize.com/webar-for-beginners/" target="_blank" rel="noreferrer" >article</a> to learn how to do this!</h2>
	  </div>
	</div>

	<div className="section contents-section dark-bg-section">
	  <div className="container">
	    <h1>Rich content supports</h1>
	    <h2>Everything you need to build great effects!</h2>
	    <div className="row">
	      <div className="col-12 col-md-6">
		<ul>
		  <li><strong>3D models</strong> - compatible with sketchfab 3 millions of downloadable models</li>
		  <li><strong>Embed Videos</strong> - Youtube or Vimeo</li>
		  <li><strong>Chroma Videos</strong> (i.e. transparent green screen)</li>
		  <li><strong>Images</strong></li>
		  <li><strong>Audios</strong></li>
		  <li><strong>Texts</strong></li>
		  <li><strong>Custom Scripts</strong> - for interactive effects</li>
		</ul>
	      </div>
	      <div className="col-12 col-md-6 col-img">
		<FontAwesomeIcon icon='thumbs-up'/>
	      </div>
	    </div>
	  </div>
	</div>

	<div className="section quality-section light-bg-section">
	  <h1>State-of-the-art quality</h1>
	  <h2>Custom computer vision technology. We focus on one thing - image tracking, and we excel!</h2>
	  <video src={qualityDemo} autoPlay={true} loop={true}>
	  </video>
	</div>

	<div className="section features-section light-bg-section">
	  <div className="container">
	    <h1>More features</h1>
	    <div className="row">
	      <div className="col col-12 col-md-4">
		<div className="wrapper">
		  <FontAwesomeIcon icon='chart-line'/>
		  <div className="title">Analytics</div>
		  <p className="description">We provide out-of-the-box usage analytics to help you keep track of daily visitors!</p>
		</div>
	      </div>
	      <div className="col col-12 col-md-4">
		<div className="wrapper">
		  <FontAwesomeIcon icon='tablet-alt'/>
		  <div className="title">99.99% Device Compatibility</div>
		  <p className="description">For old devices where AR is not available, it will automatically fallback to a non-AR mode.</p>
		</div>
	      </div>
	      <div className="col col-12 col-md-4">
		<div className="wrapper">
		  <FontAwesomeIcon icon='tachometer-alt'/>
		  <div className="title">Optimized Speed</div>
		  <p className="description">We compressed all the contents into a minimized build and deliver it to end users over CDN.</p>
		</div>
	      </div>
	    </div>
	  </div>
	</div>

	<div className="section pricing-section dark-bg-section">
	  <div className="container">
	    <h1>Pricing</h1>
	    <h2>Simple, Transparent and Generous</h2>
	  
	    <div className="plans row">
	      <div className="col col-12 col-md-6 plan">
		<div className="title">Free</div>
		<div className="price">--</div>

		<div className="features">
		  <div className="item">
		    <FontAwesomeIcon icon={['far', 'check-square']}/>
		    unlimited views
		  </div>
		  <div className="item">
		    <FontAwesomeIcon icon={['far', 'check-square']}/>
		    unlimited storage*
		  </div>
		  <div className="item">
		    <FontAwesomeIcon icon={['far', 'check-square']}/>
		    online editor (full features)
		  </div>
		  <div className="item">
		    <FontAwesomeIcon icon={['far', 'square']}/>
		    usage statistics
		  </div>
		  <div className="item">
		    <FontAwesomeIcon icon={['far', 'square']}/>
		    permanent app URL
		  </div>
		  <div className="item">
		    <FontAwesomeIcon icon={['far', 'square']}/>
		    remove Pictarize banner
		  </div>
		</div>
	      </div>

	      <div className="col col-12 col-md-6 plan">
		<div className="title">Paid</div>
		<div className="price">$25 / project / month</div>
      
		<div className="features">
		  <div className="item">
		    <FontAwesomeIcon icon={['far', 'check-square']}/>
		    unlimited views
		  </div>
		  <div className="item">
		    <FontAwesomeIcon icon={['far', 'check-square']}/>
		    unlimited storage*
		  </div>
		  <div className="item">
		    <FontAwesomeIcon icon={['far', 'check-square']}/>
		    online editor (full features)
		  </div>
		  <div className="item">
		    <FontAwesomeIcon icon={['far', 'check-square']}/>
		    usage statistics
		  </div>
		  <div className="item">
		    <FontAwesomeIcon icon={['far', 'check-square']}/>
		    permanent app URL**
		  </div>
		  <div className="item">
		    <FontAwesomeIcon icon={['far', 'check-square']}/>
		    remove Pictarize banner
		  </div>
		</div>
	      </div>
	    </div>

	    <div className="footnote">* To avoid abuse, we do impose soft limit, which can be increased gradually upon requests.</div>
	    <div className="footnote">** Your app URL will be XXX.app.pictarize.com (where XXX is whatever you want).This URL is permanent across your project builds, allowing you to update the app continuously after published.</div>
	  </div>
	</div>

	<div className="section contact-section light-bg-section">
	  <div className="container">
	    <h1>Contact Us</h1>
	    <h2>hello@pictarize.com</h2>
	  </div>
	</div>
	<Footer/>
      </div>
    </Layout>
  )
}

export default IndexPage
