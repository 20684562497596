import * as React from "react"

const Header = () => {
  return (
    <div className="header">
      <div className="container">
	<div className="brand">
	  <a href="/">
	    <h1>Pictarize</h1>
	    <h2>Augmenting any pictures</h2>
	  </a>
	</div>
    	<div className="items">
    	  <a href="/guide/editor" className="item">Studio Manual</a>
    	  {/*
    	  <div className="item">How it works</div>
    	  <div className="item">Features</div>
    	  <div className="item">Pricing</div>
	  */}
    	  <a className="studio ga-event" data-ga-event="launch_studio" href="https://studio.pictarize.com" target="_blank" rel="noreferrer">Launch Studio</a>
	</div>
      </div>
    </div>
  )
}

export default Header;

